import React from "react"
import { Avatar as AvatarMui } from "@mui/material"
import { Element1, Element2, Element3, StyledCircle, Wrapper } from "./styles"

const Avatar = ({ variant, ...rest }) => {
  if (variant === "big") {
    return (
      <Wrapper>
        <Element1 />
        <Element2 />
        <Element3 />
        <StyledCircle />
        <AvatarMui sx={{ width: "150px", height: "150px" }} {...rest} />
      </Wrapper>
    )
  }
  return <AvatarMui {...rest} sx={{ width: "50px", height: "50px" }} />
}

export default Avatar
