import React from "react"
import Layout from "hoc/Layout"
import Header from "containers/Header/Header"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Opinions from "containers/Feedback/Opinions/Opinions"
import CertificatesAndMemberships from "containers/Feedback/CertificatesAndMemberships/CertificatesAndMemberships"

const Feedbacks = () => {
  const breadcrumbsData = [
    {
      title: "Startseite",
      link: "/",
    },
    {
      title: "Über uns",
      link: "/uber-uns/firma",
    },
    "Bewertungen und Zertifikate",
  ]

  const { feedbackMain } = useStaticQuery(
    graphql`
      query {
        feedbackMain: file(name: { eq: "feedback-main" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 90
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  const mainImage = getImage(feedbackMain)
  const title = <>Bewertungen und Zertifikate</>
  return (
    <Layout
      breadcrumbsData={breadcrumbsData}
      seoTitle="Bewertungen und Zertifikate"
    >
      <Header
        title={title}
        mainImage={mainImage}
        mainImageAlt={"Bewertungen und Zertifikate"}
      />
      <Opinions />
      <CertificatesAndMemberships />
    </Layout>
  )
}

export default Feedbacks
