import { Card, Grid } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Font24 from "components/Fonts/Font24"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { options } from "constants/richTextOptions"

const CertificateCard = ({ name, logo, description }) => {
  const image = getImage(logo)

  return (
    <Card
      sx={{
        maxWidth: { xs: "550px", md: "none" },
        width: "100%",
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid
          item
          xs={12}
          md={4}
          alignItems="center"
          justifyContent="center"
          sx={{
            display: "flex",
            width: "100%",
            borderRight: { xs: "none", md: "1px solid #3bb2c440" },
            borderBottom: { xs: "1px solid #3bb2c440", md: "none" },
            paddingBottom: { xs: "20px", md: 0 },
            marginBottom: { xs: "20px", md: 0 },
          }}
        >
          <GatsbyImage image={image} alt={name} style={{ maxWidth: 250 }} />
        </Grid>
        <Grid item xs={12} md={7}>
          <Font24 mb={2}>{name}</Font24>
          <>{renderRichText(description, options)}</>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CertificateCard
