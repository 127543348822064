import React from "react"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import { graphql, useStaticQuery } from "gatsby"
import { Grid } from "@mui/material"
import CertificateCard from "../CertificateCard/CertificateCard"

const CertificatesAndMemberships = () => {
  const data = useStaticQuery(graphql`
    query CertificatesAndMembershipsQuery {
      allContentfulCertyfikatyICzlonkostwa(
        sort: { order: ASC, fields: order }
      ) {
        nodes {
          contentful_id
          description {
            raw
          }
          name
          logo {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 90
              placeholder: NONE
            )
          }
        }
      }
    }
  `)

  const certAndMemb = data.allContentfulCertyfikatyICzlonkostwa.nodes

  return (
    <section>
      <Hr mb={4} mt={10} />
      <Font42 mb={6}>Zertifikate und Mitgliedschaft</Font42>
      <Grid container rowSpacing={4}>
        {certAndMemb.map(item => (
          <Grid
            key={item.contentful_id}
            item
            xs={12}
            container
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <CertificateCard {...item} />
          </Grid>
        ))}
      </Grid>
    </section>
  )
}

export default CertificatesAndMemberships
