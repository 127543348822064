import styled from "styled-components"
import { Grid } from "@mui/material"

export const Wrapper = styled(Grid)`
  && {
    width: 158px;
    height: 158px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 10px;
  }
`
export const StyledCircle = styled(Grid)`
  && {
    width: 158px;
    height: 158px;
    border-radius: 50%;
    position: absolute;
    background: rgb(84, 68, 133);
    background: linear-gradient(
      56deg,
      rgba(84, 68, 133, 1) 0%,
      rgba(59, 178, 196, 1) 100%
    );
  }
`

export const Element1 = styled.span`
  && {
    position: absolute;
    top: 0px;
    right: 0;
    width: 50px;
    height: 50px;
    background: rgba(84, 68, 133, 0.08);
    border-radius: 50%;
  }
`
export const Element2 = styled.span`
  && {
    position: absolute;
    bottom: 0px;
    left: -20px;
    width: 100px;
    height: 100px;
    background: rgba(84, 68, 133, 0.15);
    border-radius: 50%;
  }
`
export const Element3 = styled.span`
  && {
    position: absolute;
    bottom: -15px;
    right: -10px;
    width: 100px;
    height: 100px;
    background: rgba(59, 178, 196, 0.05);
    border-radius: 50%;
  }
`
